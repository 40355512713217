var defaultValues = { user: undefined };

const app = (state = defaultValues, action) => {
	switch (action.type) {
		case 'SET_USER':
			return Object.assign({}, state, { user: action.user });
		default:
			return state;
	}
};

export default app;
